import { createContext } from 'react';

import { MappedCart } from 'types/MappedCart';
import { defaultCart } from 'consts/cart';
import { type HasuraErrorCode } from 'utils/apiError';

type CartContextType = {
  cart: MappedCart;
  isLoading: boolean;
  onAddToCart: (
    variantId: number,
    quantity: number,
    complementaryProducts?: number[],
    silent?: boolean
  ) => Promise<void>;
  onAddComplementaryToCart: (variantId: number, complementaryVariantId: number) => Promise<void>;
  onDeleteFromCart: (id: number) => Promise<void>;
  onUpdateOrderItem: (id: number, quantity: number) => Promise<void>;
  onClearOrderItems: () => Promise<void>;
  onSetPromoCode: (code: Nullable<string>) => Promise<HasuraErrorCode | 'UNKNOWN' | void>;
};

export const CartContext = createContext<CartContextType>({
  cart: defaultCart,
  isLoading: true,
  onAddToCart: async () => {},
  onAddComplementaryToCart: async () => {},
  onDeleteFromCart: async () => {},
  onUpdateOrderItem: async () => {},
  onClearOrderItems: async () => {},
  onSetPromoCode: async () => {},
});
